'use strict';

const _ = require("lodash");

$(() => {

    // Scroll animación días

    const scrollElements = document.querySelectorAll(".js-scroll");

    const elementInView = (el, dividend = 1) => {
        const elementTop = el.getBoundingClientRect().top;

        return (
            elementTop <=
            (window.innerHeight || document.documentElement.clientHeight) / dividend
        );
    };

    const displayScrollElement = (element) => {

        if (!element.classList.contains('scrolled')) {
            animateNumber();
        }
        element.classList.add("scrolled");
    };

    const handleScrollAnimation = () => {
        scrollElements.forEach((el) => {
            if (elementInView(el, 1.25)) {
                displayScrollElement(el);
            }
        })
    }

    window.addEventListener("scroll", () => {
        handleScrollAnimation();
    });

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function animateNumber(elClass = '.project-number') {
        const number = $(elClass);

        if (!number.length) {
            return;
        }

        const dataNumber = number.attr('data-max');

        for (let index = 1; index <= dataNumber; index++) {
            await sleep(50);

            number.html(index);
        }
    }

    // FIN - Scroll animación días
    startSwipers();

    function startSwipers() {
        const countNews = $(".swiper-news").find('.swiper-slide').length > 4 ? {
            loop: true,
            autoplay: {
                delay: 3500,
                disableOnInteraction: false,
            },
        } : {};

        const swiperNews = new Swiper(".swiper-news", {
            ...countNews,
            spaceBetween: 30,
            slidesPerView: 'auto',
            pagination: {
                el: ".swiper-pagination",
            },
        });
        const countNewsSubs = $(".swiper-newsSubs").find('.swiper-slide').length > 4 ? {
            loop: true,
            autoplay: {
                delay: 3500,
                disableOnInteraction: false,
            },
        } : {};

        const swiperNewsSubs = new Swiper(".swiper-newsSubs", {
            ...countNewsSubs,
            slidesPerView: 'auto',
            spaceBetween: 30,
            pagination: {
                el: ".swiper-pagination",
            },
        });

        const countProject = $(".swiper-projects").find('.swiper-slide').length > 4 ? {
            loop: true,
            autoplay: {
                delay: 4500,
                disableOnInteraction: false,
            },
        } : {};

        const swiperProjects = new Swiper(".swiper-projects", {
            ...countProject,
            spaceBetween: 0,
            slidesPerView: 'auto',
            pagination: {
                el: ".swiper-pagination",
            },
        });

        const countMyProjects = $(".swiper-myprojects").find('.swiper-slide').length > 1 ? {
            loop: true,
            autoplay: {
                delay: 4500,
                disableOnInteraction: false,
            }
        } : {};

        const swiperMyProjects = new Swiper(".swiper-myprojects", {
            effect: "creative",
            grabCursor: true,
            centeredSlides: true,
            centeredSlidesBounds: true,
            creativeEffect: {
                prev: {
                    shadow: true,
                    translate: [0, 0, -400],
                },
                next: {
                    translate: ["100%", 0, 0],
                },
            },
            ...countMyProjects,
        });

        $('.btn-iplusd').on('click', function () {
            swiperMyProjects.slideNext();
        })

        const swiperService = new Swiper(".swiper-service", {
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            mousewheel: true,
            allowTouchMove: true,
            grabCursor: true,
            effect: "creative",
            creativeEffect: {
                prev: {
                    shadow: true,
                    translate: ["-130%", 0, -500],
                },
                next: {
                    shadow: true,
                    translate: ["130%", 0, -500],
                },
            },
        });
    }


    $('.swiper-news').on('click', '.news-content-main', function () {
        const card = $(this);
        const id = card.attr('data-id');
        getNew(id);
    });

    $('.swiper-news').on('keypress', '.news-content-main', function (e) {
        const card = $(this);
        const id = card.attr('data-id');

        if (e.which === 13) {
            getNew(id);
        }
    });

    $('.swiper-newsSubs').on('click', '.news-content-main', function () {
        const card = $(this);
        const id = card.attr('data-id');
        getNew(id, 'subv');
    });

    $('.swiper-newsSubs').on('keypress', '.news-content-main', function (e) {
        const card = $(this);
        const id = card.attr('data-id');

        if (e.which === 13) {
            getNew(id, 'subv');
        }
    });

    $('.btn-contact-service').on('click', function () {
        const id = $(this).attr('data-id');
        $('#idservice').val(id);

        const title = $('html').attr('lang') == 'es' ? 'Más información' : 'More information';
        $('#titleContact').html(title);
        $('#service-modal').modal('hide');
        $('#contact-modal').modal('show');
    });

    $('#btn-newsSubs').on('click', function () {
        const id = $(this).attr('data-id');
        $('#idnewsubs').val(id);

        const title = $('html').attr('lang') == 'es' ? 'Más información' : 'More information';
        $('#titleContact').html(title);
        $('#new-modal').modal('hide');
        $('#contact-modal').modal('show');
    });

    function getNew(id, type = "new") {
        const newModal = $('#new-modal');

        showLoaded();
        $.get(`news/${id}`)
            .done((res) => {
                const newData = res.new;

                $('#new-date').html(newData.date);
                $('#new-title').html(newData.title);
                $('#new-desc').html(newData.description);
                $('#new-image').attr('src', newData.image_path).attr('alt', newData.title);
                $('#new-content').html(newData.content);

                if (type == 'subv') {
                    $('#btn-newsSubs').attr('data-id', newData.id).removeClass('d-none')
                } else {
                    $('#btn-newsSubs').addClass('d-none')
                }

                const relArticles = res.news;

                if (relArticles.length > 0) {
                    $('#news-rel-articles').html('');
                    relArticles.forEach(el => {
                        let div = articleRel(el, type);
                        $('#news-rel-articles').append(div)
                    });
                    $('#news-relation').removeClass('d-none')
                } else {
                    $('#news-relation').addClass('d-none')
                }

                let title = {
                    'subv': {
                        'es': 'Más subvenciones',
                        'en': 'More grants'
                    },
                    'new': {
                        'es': 'Más noticias',
                        'en': 'More news'
                    }
                }
                let currentTitle = title[type][$('html').attr('lang') ? 'es' : '']
                $('#news-relation-title').html(currentTitle)


                newModal.modal('show');
            }).fail((err) => {
                const errors = err.responseJSON;

                Swal.fire({
                    title: 'Error',
                    html: errors.message,
                    icon: 'error',
                })
            }).always(() => {
                hideLoaded();
            })

    }

    $('#news-rel-articles').on('click', '.rel-article', function () {
        const card = $(this);
        const id = card.attr('data-id');
        const type = card.attr('data-type');
        getNew(id, type);
    });

    function articleRel(data, type) {
        return `<div class="col-12">
    <article class="rel-article" data-type="${type}" data-id="${data.id}">
        <div class="mb-3 d-block">
            <img src="/images/${data.image_path}" class="img-fluid rounded-2 d-block shadow-sm" alt="${data.title} " title="${data.title}">
        </div>
        <h5 class="link-dark text-break">${data.title}</h5>
        <p class="text-secondary"><small>${data.created}</small></p>
    </article>
</div>`
    }


    function showLoaded() {
        $('body').append('<div id="bg-load"><i class="fas fa-circle-notch fa-spin fa-2x"></i></div>');
    }

    function hideLoaded() {
        $('#bg-load').remove();
    }

    const macOrIos = /(Mac|iPhone|iPod|iPad)/i.test(navigator.userAgent);

    if (macOrIos) {

        function bgScroll(bgEl, scroll, type = "pixel") {
            const bg = $(bgEl);
            if (bg.length) {

                const defaultPosition = type == 'pixel' ? '0px' : '50%';

                if (bg.offset().top <= scroll.scrollTop()) {

                    const diff = (scroll.scrollTop() - bg.offset().top);
                    const heightBg = bg.height();

                    let calcPosition = 0;

                    switch (type) {
                        case 'pixel':
                            calcPosition = ((diff / bg.offset().top) * 100) / 2;
                            let height = (heightBg / 6.7);
                            calcPosition = '-' + (calcPosition > height ? height : calcPosition) + 'px';

                            break;
                        case 'percent':
                            calcPosition = (100 - (100 * diff / heightBg)) / 2;
                            calcPosition = (calcPosition < 0 ? 0 : calcPosition) + '%';
                            break;
                    }

                    bg.css("background-position-y", calcPosition);

                } else {
                    bg.css("background-position-y", defaultPosition);
                }
            }
        }

        $(window).scroll(function () {
            const scroll = $(this);
            bgScroll("#header .bg-image", scroll)
            bgScroll("#contact", scroll)
            bgScroll("#footer", scroll)
            bgScroll("#iplusd", scroll)

            bgScroll("#dev .dev-content", scroll)

            bgScroll("#marketing .marketing-content", scroll)

        });
    }

});





var navUserAgent = navigator.userAgent;
var browserName = navigator.appName;
var browserVersion = '' + parseFloat(navigator.appVersion);
var majorVersion = parseInt(navigator.appVersion, 10);
var tempNameOffset, tempVersionOffset, tempVersion;


if ((tempVersionOffset = navUserAgent.indexOf("Opera")) != -1) {
    browserName = "Opera";
    browserVersion = navUserAgent.substring(tempVersionOffset + 6);
    if ((tempVersionOffset = navUserAgent.indexOf("Version")) != -1)
        browserVersion = navUserAgent.substring(tempVersionOffset + 8);
} else if ((tempVersionOffset = navUserAgent.indexOf("MSIE")) != -1) {
    browserName = "Microsoft Internet Explorer";
    browserVersion = navUserAgent.substring(tempVersionOffset + 5);
} else if ((tempVersionOffset = navUserAgent.indexOf("Chrome")) != -1) {
    browserName = "Chrome";
    browserVersion = navUserAgent.substring(tempVersionOffset + 7);
} else if ((tempVersionOffset = navUserAgent.indexOf("Safari")) != -1) {
    browserName = "Safari";
    browserVersion = navUserAgent.substring(tempVersionOffset + 7);
    if ((tempVersionOffset = navUserAgent.indexOf("Version")) != -1)
        browserVersion = navUserAgent.substring(tempVersionOffset + 8);
} else if ((tempVersionOffset = navUserAgent.indexOf("Firefox")) != -1) {
    browserName = "Firefox";
    browserVersion = navUserAgent.substring(tempVersionOffset + 8);
} else if ((tempNameOffset = navUserAgent.lastIndexOf(' ') + 1) < (tempVersionOffset = navUserAgent.lastIndexOf('/'))) {
    browserName = navUserAgent.substring(tempNameOffset, tempVersionOffset);
    browserVersion = navUserAgent.substring(tempVersionOffset + 1);
    if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
    }
}

if ((tempVersion = browserVersion.indexOf(";")) != -1)
    browserVersion = browserVersion.substring(0, tempVersion);
if ((tempVersion = browserVersion.indexOf(" ")) != -1)
    browserVersion = browserVersion.substring(0, tempVersion);

$(() => {
    // console.log(browserName);
    if (browserName == "Safari") {
        $('.imagen-solo-safari').removeClass('d-none')
        $('.bg-img, #contact, #footer, #header .bg-image, #dev .dev-content, #marketing .marketing-content').css('background-attachment', 'initial')

        // Sección desarrollo digital
        $('#dev .dev-content').css('background-image', 'none')
        $('.dev-text').css('margin-top', '0')
        $('#dev .dev-bg').css('visibility', 'visible').css('height', 'auto')
        $('#dev .dev-bg .ocultar-imagen-solo-safari').css('display', 'none')

        // Sección marketing inusual
        $('#marketing .marketing-content').css('background-image', 'none')
        $('#marketing .marketing-text').css('margin-bottom', '0')
        $('#marketing .marketing-bg').css('visibility', 'visible').css('height', 'auto')
        $('#marketing .marketing-bg .ocultar-imagen-solo-safari').css('display', 'none')
    }
})
